<template>
  <ValidationObserver>
    <div class="sm:px-6 lg:px-10 xl:px-15 py-6 w-full bg-promy-gray-120">
      <ValidateModal v-if="showModal" @isCancel="continuer" @isValide="quitter">
        <slot slot="message_body"
          >Si vous quittez le dossier, vos données ne seront pas sauvegardées.
          Continuer / Quitter</slot
        >
        <slot slot="button_valide">Quitter</slot>
        <slot slot="button_cancel">Continuer</slot>
      </ValidateModal>

      <router-view></router-view>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      next_route: '',
      showModal: false,
      id_dossier: null,
      parentUrl: '/dossier-promoteurs',
      links: [
        {
          name: 'informations',
          path: 'informations',
          componentName: 'informations',
          key: 'informations',
          is_exist: true,
          path_route: 'informations',
        },
        {
          name: 'la commune',
          path: 'commune',
          componentName: 'commune',
          key: 'commune',
          is_exist: true,
          path_route: 'commune',
        },
        {
          name: 'synthese du marche',
          path: 'synthese-du-marche',
          componentName: 'synthese-du-marche',
          key: 'synthese_marche_immobilier',
          is_exist: true,
          path_route: 'synthese-du-marche',
        },
        {
          name: 'contexte général',
          path: 'contexte-general',
          componentName: 'contexte-generale',
          key: 'contexte_generale',
          is_exist: true,
          path_route: 'contexte-generale',
        },
        {
          name: 'localisation',
          path: 'localisation',
          componentName: 'localisation',
          key: 'localisation',
          is_exist: true,
          path_route: 'localisation',
        },
        {
          name: 'urbanisme',
          path: 'urbanisme',
          componentName: 'urbanisme',
          key: 'urbanisme',
          is_exist: true,
          path_route: 'urbanisme',
        },
        {
          name: 'le projet by promy',
          path: 'le-projet-by-promy',
          componentName: 'le-projet-by-promy',
          key: 'projetbypromy',
          is_exist: true,
          path_route: 'le-projet-by-promy',
        },
        {
          name: 'Descriptif projet',
          path: 'descriptif-projet',
          componentName: 'descriptif-projet',
          key: 'descriptif_projet',
          is_exist: false,
          path_route: 'descriptif-projet',
        },
        {
          name: 'etude commerciale',
          path: 'etude-commerciale',
          componentName: 'etude-commerciale',
          key: 'etude_commerciale',
          is_exist: true,
          path_route: 'etude-commerciale',
        },
        {
          name: 'rapport technique',
          path: 'rapport-technique',
          componentName: 'rapport-technique',
          key: 'rapport_technique',
          is_exist: true,
          path_route: 'rapport-technique',
        },
        {
          name: 'Bilan Financier',
          path: 'bilan-financier',
          componentName: 'dossier-bilan-financier',
          key: 'bilan_financier',
          is_exist: true,
          path_route: 'bilan-financier',
        },
        {
          name: 'dossier photo',
          path: 'dossier-photo',
          componentName: 'dossier-photo',
          key: 'dossier_photos',
          is_exist: true,
          path_route: 'dossier-photo',
        },
      ],
    }
  },
  methods: {
    continuer() {
      this.showModal = false
    },
    quitter() {
      this.showModal = false
      this.$store.commit('dossierpromoteur/setIsSavingOrUpdatingDP', true)

      this.$router.push(this.next_route)
    },
  },
  computed: {
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      map: 'defaultmap/map',
      api_is_loading: 'loading/is_loading',
      is_saving_or_updating_DP: 'dossierpromoteur/getIsSavingOrUpdatingDP',
    }),
  },
  created() {
    this.$store.commit(
      'dossierpromoteur/setMode',
      this.$route.params.id ? false : true,
    )
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  beforeRouteEnter(to, from, next) {
    if (to.name != 'informations') {
      const url = to.params.id
        ? `${to.matched[1].path}/informations/${to.params.id}`
        : `${to.matched[1].path}/informations`
      next(url)
    } else {
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.is_saving_or_updating_DP) {
      this.next_route = to.path
      this.showModal = true
    } else next()
  },

  destroyed() {
    this.$destroy()
    this.$store.commit('dossierpromoteur/resetDossierPromoteur')
    this.$store.commit('parcelle/resetParcelle')
    this.$store.commit('loading/set_loading', true)
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>
